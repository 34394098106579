import DualPhones from '../../assets/home/img/Iphonedual/iPhone Dual.png';
import Email from "../../assets/home/SVGfiles/Email.svg";
import AppStore from '../../assets/home/img/appygoogle/Apple.svg';
import Googleplay from '../../assets/home/img/appygoogle/Google Play.svg';
import Faqs from "../../assets/home/SVGfiles/Faqs.svg";
import RingM from "../../assets/Isotipo.png";
import Whatsapp from '../../assets/home/img/Redes/Whatsapp.svg';
import Instagram from '../../assets/home/img/Redes/Instagram.svg';
import './Footer.css';

const scrollVerprecios = () => {
    const pricesSection = document.getElementById('precios');
    pricesSection.scrollIntoView({ behavior: 'smooth' });
};

const Footer = () =>
{
    return (
        <div className="all-footer">
                <div className="blue-rectangle">
                    <div className="content-container">
                        <div className="text-footer">
                            <h1 className="controla">Controla el acceso</h1>
                            <h1 className="hogar">a tu hogar</h1>
                            <p className="mini-text">Regístrate y obtén acceso inmediato</p>
                            <button onClick={scrollVerprecios} type="submit" className="button-precios"> 
                                Ver precios 
                            </button>
                            <div className="store-links">
                                <button type="button" className="store-button">
                                    <img src={Googleplay} alt="Google Play Logo" className="store-icon" />
                                    <p className='mobilenameapp'>Google Play</p>
                                </button>
                                <button type="button" className="store-button">
                                    <img src={AppStore} alt="App Store Logo" className="store-icon" />
                                    <p className='mobilenameapp'>App Store</p>
                                </button>
                            </div>
                        </div>
                        <img src={DualPhones} alt="Dual Phones" className="dual-phones" />
                    </div>
                </div>
                <div className='footer-contacto'>
                        <div className='FAQs-container'>
                        <img src={Faqs} alt="FAQS" className='faqs-image'/>
                            <h1 className='Faqtext1'>
                                ¿Tienes preguntas?
                            </h1>
                            <a href='' className='faqtext2'>
                                FAQs
                            </a>
                        </div>
                        <div className='mail-container'>
                        <img src={Email} alt="EMAIL" className='mailimage'/>
                            <h1 className='mailtext1'>
                                Contáctanos
                            </h1>
                            <p className='mailtext2'>
                                info@ringm.cl
                            </p>
                        </div>
                </div>
                        <div className='futter-legale'>
                            <img src={RingM} alt="ringMe Logo" className=''/>
                            <p className=''>
                                Todos los derechos reservados 2023
                            </p>
                            <a href='#' className='tyc'>
                                Terminos y condiciones
                            </a>
                            <div className=''>
                                <button type='button'  className='ig'>
                                    <img src={Instagram} alt="Instagram" />
                                </button>
                                <button className='wh'>
                                    <img src={Whatsapp} alt="Whatsapp" />
                                </button>
                            </div>
                        </div>
            </div>
    );
}

export default Footer;
