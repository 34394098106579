import React, { useState, useEffect, useRef } from 'react';
import Mas from '../../assets/home/SVGfiles/+.svg';
import Menos from '../../assets/home/SVGfiles/menos.svg';
import Comillas from '../../assets/home/img/pricing/comillas.png';
import Atras from '../../assets/home/img/pricing/Arrow-1.svg';
import Adelante from '../../assets/home/img/pricing/Arrow-2.svg';
import emailjs from 'emailjs-com'; 
import testimoniosData from '../../assets/Testimonios/testimonios.json';
import Email from "../../assets/home/SVGfiles/Email.svg";
import FormImage from '../../assets/home/img/formImage.png';
import RingM from "../../assets/Isotipo.png";
import './pricing.css'

const Pricing = () => {
    
    const [stock, setStock] = useState(1);
    const [testimonios, setTestimonios] = useState([]);
    const [testimonioActual, setTestimonioActual] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); 
    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        cantidadCasas: stock,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef(); 

    useEffect(() => {
        if (Array.isArray(testimoniosData)) {
            setTestimonios(testimoniosData);
        }
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isModalOpen]);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            cantidadCasas: stock,
        }));
    }, [stock]);

    const incrementStock = () => {
        setStock(stock + 1);
    };

    const decrementStock = () => {
        if (stock > 1) {
            setStock(stock - 1);
        }
    };

    const handleStockChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setStock(value);
        } else {
            const number = parseInt(value, 10);
            if (!isNaN(number) && number > 0) {
                setStock(number);
            }
        }
    };

    const handleStockBlur = () => {
        if (stock === '') {
            setStock(1);
        }
    };

    const mostrarSiguienteTestimonio = () => {
        setTestimonioActual((testimonioActual + 1) % testimonios.length);
    };

    const mostrarTestimonioAnterior = () => {
        const anterior = (testimonioActual - 1 + testimonios.length) % testimonios.length;
        setTestimonioActual(anterior);
    };

    const calcularPrecio = (stock) => {
        const IVA = 0.19;
        let uf = 1.9;
        if (stock > 30) {
            uf += (Math.ceil((stock - 30) / 10)) * 0.1;
            if (uf > 2.9) {
                uf = 2.9;
            }
        }
        const precioConIVA = uf * (1 + IVA);
        const precioSinIVA = uf;
        return precioSinIVA.toFixed(2);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); 

        const templateParams = {
            nombre: formData.nombre,
            correo: formData.correo,
            telefono: formData.telefono,
            cantidadCasas: stock,
        };

        emailjs.send('service_bh235po', 'template_tssvo1q', templateParams, 'eiYNDxW4fyXp2qpw8')
            .then((result) => {
                console.log(result.text);
                setFormSubmitted(true); 
                setIsSubmitting(false); 
            }, (error) => {
                console.error(error.text);
                setIsSubmitting(false); 
            });

        setFormData({
            nombre: '',
            correo: '',
            telefono: '',
            cantidadCasas: 1,
        });
        setStock(1);
    };

    const scrollToModule = () => {
        const moduleElement = document.getElementById('modal');
        if (moduleElement) {
            moduleElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='all-pricing' id='precios'>
            <div className="pricing-container">
                <h5 className="pricing-title">Precios</h5>
                <p className="pricing-text">Nuestro precio es simple sin</p>
                <p className="pricing-text">tarifas ocultas</p>
            </div>
            <div className='pricing-squares' id='modal'> 
                <div className="square-container">
                    <div className="square-header">
                        <p>CASAS PARTICULARES</p>
                    </div>
                    <h1 className="square-title">Proximamente</h1>
                    <p className="square-subtext">Pago por 1 hogar</p>
                    <button className="square-button" disabled>PROXIMAMENTE</button>
                </div>
                <div className="square-container2">
                    <div className="square-header">
                        <p>CONDOMINIOS O COMUNIDADES</p>
                    </div>
                    <div className="square-pricing">
                        <span className="pricing-uf">UF {calcularPrecio(stock)}</span>
                        <span className="pricing-iva">+ IVA / mes</span>
                    </div>
                    <div className="stock-controls">
                        <button className="control-button" onClick={decrementStock}>
                            <img src={Menos} alt="Menos" />
                        </button>
                        <input
                            className="stock-input"
                            value={stock}
                            onChange={handleStockChange}
                            onBlur={handleStockBlur}
                            min="1"
                        />
                        <button className="control-button" onClick={incrementStock}>
                            <img src={Mas} alt="Mas" />
                        </button>
                    </div>
                    <button
                        className="start-button"
                        onClick={() => {
                            setIsModalOpen(true);
                            scrollToModule();
                        }}
                    >
                        Empezar a usar RingM
                    </button>
                </div>
            </div>

            <div className='test-in-row'>
                <div className='Testimonios-title'> 
                    <h5 className='testimonios-text'>
                        TESTIMONIOS
                    </h5>
                    <p className="testimonios-bolder-text">
                        Lo que opinan
                    </p>
                    <p className="testimonios-bolder-text">
                        nuestros usuarios
                    </p>
                </div>
                <div className='test-in-row'>   
                    <div class="container-testimonios">
                        <img src={Comillas} alt="Comillas" class="comillas-testimonios comillas-rotada-testimonios" />
                        <div class="descripcion-testimonios">
                            <p className='testimonios-description-text'>{testimonios[testimonioActual]?.descripcion}</p>
                        </div>
                        <img src={Comillas} alt="Comillas" class="comillas-testimonios" />
                        <div class="nombre-usuario-testimonios">
                            <p class="nombre-testimonios">{testimonios[testimonioActual]?.nombre}</p>
                            <p class="usuario-testimonios">{testimonios[testimonioActual]?.usuario}</p>
                        </div>
                        <div class="botones-testimonios">
                            <button onClick={mostrarTestimonioAnterior}>
                                <img src={Atras} alt="Atrás" />
                            </button>
                            <button onClick={mostrarSiguienteTestimonio}>
                                <img src={Adelante} alt="Adelante" />
                            </button>
                        </div>
                    </div>
                </div>           
            </div>

            {isModalOpen && (
                <div className=''>
                    <div className="modal-overlay" onClick={() => setIsModalOpen(false)}></div>
                    <div className="modal-container">
                        <img src={RingM} alt="RingMlogo" className='ringm-modal-logo' />
                        <img src={FormImage} alt="Formulario Imagen" />
                        <span onClick={() => setIsModalOpen(false)}>&times;</span>
                        {formSubmitted ? (
                            <div>
                                <p>Listo! te contactaremos en breve.</p>
                            </div>
                        ) : (
                            <>
                                <h2 className='solicita-ahora-title'>Solicita Ahora!</h2>
                                <form ref={formRef} onSubmit={handleSubmit}>
                                    <label>
                                        <p className='labeltext'> Nombre: </p>
                                        <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} required />
                                    </label>
                                    <label>
                                       <p className='labeltext'> Correo: </p>
                                        <input type="email" name="correo" value={formData.correo} onChange={handleInputChange} required />
                                    </label>
                                    <label>
                                        <p className='labeltext'> Teléfono: </p>
                                        <input type="tel" name="telefono" value={formData.telefono} onChange={handleInputChange} required />
                                    </label>
                                    <button type="submit">{isSubmitting ? 'Cargando...' : 'Solicitar!'}</button>
                                </form>
                            </>
                        )}
                    </div>

                    {!formSubmitted && (
                        <div className="">
                            <p>ESTÁS SOLICITANDO:</p>
                            <p>CONDOMINIOS O COMUNIDADES</p>
                            <p>CANTIDAD: x{stock}</p>
                            <p>TOTAL: UF {calcularPrecio(stock)} + IVA</p>
                            <br />
                        </div>
                    )}
                </div>
            )}

        </div>
    );
}

export default Pricing;
