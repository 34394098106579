import RingM from "../../assets/home/img/RingM 2/RingM.png";
import SecurityIcon from "../../assets/home/icon/Seguridad.svg";
import GestionaIcon from "../../assets/home/icon/Gestiona.svg";
import GestionaIcon1 from "../../assets/home/icon/Gestiona (1).svg";
import Residentes from "../../assets/home/img/Residentes/Residentes.png";
import Arrow from "../../assets/home/SVGfiles/Arrow-3.svg";
import Iphonebackground from '../../assets/home/img/Iphonefondogris/BG - Mobile@2x.png';
import Check from '../../assets/home/SVGfiles/Check-1.svg';
import CasaPlaya from '../../assets/home/img/CasasRingMe/Casa de la playa.png';
import CasaManuel from '../../assets/home/img/CasasRingMe/Casa de Manuel.png';
import CasaPrincipal from '../../assets/home/img/CasasRingMe/Casa Principal.png';
import Notificaciones from '../../assets/home/img/notificaciones/Notificaciones.png';
import Invitaciones from '../../assets/home/img/notificaciones/Invitaciones.png';
import './Product.css';

const Product = () => {
    return (
        <div className='all-product' id="acerca-de">
            <div className='mt-40'>                    
                <div className="about-container">
                  <h5 className="about-title">Acerca de</h5>
                  <h1 className="main-title">
                    Simplifica el acceso a tu
                  </h1>
                  <h1 className="main-title">
                    hogar con <span className="inline-container">
                            <img src={RingM} alt="RingM logo" className="ringm-logo" />
                        </span>
                  </h1>
                </div>
            </div>

            <div class="row-productrm">
              <div class="column-productrm">
                <div class="image-container-productrm">
                  <img src={GestionaIcon} alt="Security Icon" class="icon-productrm" />
                </div>
                <p class="text-productrm">Gestiona de manera sencilla los usuarios</p>
                <p class="text-productrm">que pueden ingresar. Invita a tus</p>
                <p class="text-productrm">familiares y amigos como residentes para</p>
                <p class="text-productrm">que puedan acceder de forma</p>
                <p class="text-productrm">permanente.</p>
              </div>
                
              <div class="column-productrm">
                <div class="image-container-productrm">
                  <img src={GestionaIcon1} alt="Security Icon" class="icon-productrm" />
                </div>
                <p class="text-productrm">Simplifica tu vida, olvídate de los timbres</p>
                <p class="text-productrm">tradicionales y disfruta de un acceso</p>
                <p class="text-productrm">rápido y conveniente con nuestra</p>
                <p class="text-productrm">aplicación automatizando el ingreso a tu</p>
                <p class="text-productrm">hogar.</p>
              </div>
                
              <div class="column-productrm">
                <div class="image-container-productrm">
                  <img src={SecurityIcon} alt="Security Icon" class="icon-productrm" />
                </div>
                <p class="text-productrm">RingM utiliza tecnología de seguridad</p>
                <p class="text-productrm">avanzada para proteger el acceso a tu</p>
                <p class="text-productrm">hogar o condominio, garantizamos que</p>
                <p class="text-productrm">solo las personas autorizadas puedan</p>
                <p class="text-productrm">ingresar.</p>
              </div>
            </div>

            <div className="relative iphone-container">
                <img src={Iphonebackground} className="iphone-image w-9/12 mx-auto my-auto" />
                <div className="absolute top-0 left-0 w-full text-center">
                    <div className="container mx-auto rounded-2xl p-6 mt-16 image-text-margin-product-fix">
                        <p className="title-custom">
                            La forma más inteligente de
                        </p>
                        <p className='title-custom title-custom2'>
                            recibir visitas
                        </p>
                        <p className="text-pressable hidden-mobile">
                            QUIERO SABER MAS
                            <img src={Arrow} className="ml-1 inline" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;
